<template>
  <div :class="!isMobile ? 'modularC' : 'phone-modularC'">
    <img :class="isMobile ? 'phone-img' : 'img'" src="../assets/A3_00000_iSpt.png" />
    <div :class="isMobile ? 'phone-text' : 'text'">
      <img :class="isMobile ? 'phone-icon' : 'icon'" src="../assets/A3_penguin.png">
      <div :class="isMobile ? 'phone-title' : 'title'">Giggle Penguin Island</div>
      <div :class="isMobile ? 'phone-news' : 'news'">
        <h3>
          Quick English Learning
        </h3>
        <div class="content">
          Giggle mini-App is a English as second language learning game roots on Telegram.
        </div>
        <h3>
          Fun for Kids 7-9
        </h3>
        <div class="content">
          Combining with Crypto vocabulary and phrases, aiming to provide 7-9 year old a proper and fun gateway to web3
          world.
        </div>
        <div class="content">
          Easy tasks, fun themes, good future!
        </div>
        <div :class="isMobile ? 'phone-button' : 'button'">
          OPERATIONS AND FUNDING
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'modularC',
  props: ['isMobile'],
  data() {
    return {
      gifLoaded: false,
    };
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.modularC {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
  top: 0;
}
.phone-modularC {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
}

h3 {
  text-align: left;
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-top: 24px;
}

.img {
  width: 696px;
  height: 704px;
  margin: 178px 0 0 317px;
  float: left;
}

.text {
  width: 375px;
  height: 581px;
  background: #fff;
  float: left;
  margin: 207px 0 0 176px;
  border-radius: 40px;
}

.icon {
  position: absolute;
  margin: -35px 248px;
}

.title {
  font-family: Fredoka Light;
  font-weight: 600;
  font-size: 20px;
  color: #2F9C36;
  margin: 21px 30px;
}

.news {
  width: 315px;
  height: 592px;
  margin: 0 30px;
  border-top: 1px solid #DDDDDD;
  ;
}

span {
  text-align: left;
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 40px;
  margin-bottom: 5px;
}

.content {
  text-align: left;
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  word-break: keep-all;
  line-height: 24px;
  margin-bottom: 29px;
}

.button {
  width: 315px;
  height: 48px;
  background: #2F9C36;
  border-radius: 8px;
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 48px;
  text-align: center;
  margin-top: 64px;
}

.phone-img {
  width: -webkit-fill-available;
  padding: 0 20.5pt;
  margin-top: 91.5pt;
  height: 300pt;
}

.phone-icon {
  width: 48pt;
  height: 48pt;
  position: absolute;
  right: 35.5pt;
  margin-top: -26px;
}

.phone-text {
  width: 88%;
  background: #fff;
  float: left;
  margin: 6%;
  margin-bottom: 10pt;
  border-radius: 40px;
}

.phone-news {
  margin: 10pt 15pt;
  border-top: 1px solid #DDDDDD;
  text-align: -webkit-center;
}

.phone-title {
  font-family: Fredoka Light;
  font-weight: 600;
  font-size: 15pt;
  color: #2F9C36;
  margin: 13pt 15pt;
}

.phone-button {
  width: 157pt;
  padding: 12px 0;
  background: #2F9C36;
  border-radius: 8px;
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 9pt;
  color: #FFFFFF;
  line-height: 9pt;
  text-align: center;
  margin: 0 30% 20px 30%;
}
</style>
