<template>
  <div :class="!isMobile ? 'modularF' : 'phone-modularF'">
    <img :class="isMobile ? 'phone-img' : 'img'" src="../assets/A6_00000_iSpt.png" />
    <div :class="isMobile ? 'phone-text' : 'text'">>
      <img :class="isMobile ? 'phone-icon' : 'icon'" src="../assets/A6_penguin.png">
      <div class="title">KidQuoraAI</div>
      <div :class="isMobile ? 'phone-news' : 'news'">
        <div :class="isMobile ? 'phone-bubble' : 'bubble'">
          <img src="../assets/icon_avatar.png" style="float: left;" />
          <div :class="isMobile ? 'phone-dialogue':'dialogue'">
            Hey there, young explorers!Welcome to Giggle Penguin lsland, where youcan chat with our super-smart penguin
            pal,Peppy! Peppy is here to answer all yourquestions, share fun facts, and even tell jokes.
          </div>
        </div>
        <div :class="isMobile ? 'phone-bubble' : 'bubble'">
          <img src="../assets/icon_avatar.png" style="float: left;" />
          <div :class="isMobile ? 'phone-dialogue':'dialogue'">
            Just type in what you want to say, and Peppywill chat right back with you. whether you'recurious about
            penguins,Web3, need help withyour homework, or just want to share a story,Peppy is ready to talk anytime.
          </div>
        </div>
        <div class="log">Let's start chatting and make some gigglestogether!</div>
        <div class="log">(i.g.) What is $PEPPY? Tell a goofy jokeabout PEPPY!</div>
        <div :class="isMobile ? 'phone-button' : 'button'">
            COMING SOON!
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'modularF',
  props: ['isMobile'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.modularF {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
  top: 0;
}

.phone-modularF {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
}

.img {
  width: 908px;
  height: 629px;
  margin: 219px 0 0 226px;
  float: left;
}

.text {
  width: 375px;
  height: 720px;
  background: #fff;
  float: left;
  margin: 218px 0 0 192px;
  border-radius: 40px;
}

.icon {
  position: absolute;
  margin: -35px 248px;
}

.title {
  font-family: Fredoka Light;
  font-weight: 600;
  font-size: 20px;
  color: #2F9C36;
  margin: 21px 30px;
}

.news {
  width: 315px;
  height: 592px;
  margin: 0 30px;
  border-top: 1px solid #DDDDDD;
}
.bubble {
  margin-top: 16px;
  height: 212px;
}
.phone-bubble {
  margin-top: 16px;
  // width: 305pt;
  // height: 57pt;
}

span {
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 40px;
  margin-bottom: 5px;
}

.dialogue {
  float: left;
  width: 253px;
  height: 189px;
  background: #F8F8F8;
  border-radius: 10px;
  border: 1px solid #DDDDDD;
  margin-left: 6px;
  padding: 11px;
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}
.button {
  width: 315px;
  height: 48px;
  background: #2F9C36;
  border-radius: 8px;
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height: 48px;
  text-align: center;
  margin-top: 10px;
}

.phone-dialogue {
  // float: left;
  // width: 284pt;
  // height: 57pt;
  background: #F8F8F8;
  border-radius: 10px;
  border: 1px solid #DDDDDD;
  margin-left: 46px;
  padding: 8pt;
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 9pt;
  color: #000000;
  margin-bottom: 10pt;
}

.log {
  margin-left: 14%;
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 14px;
  color: #2F9C36;
  margin-top: 12px;
}

.phone-img {
  width: 100%;
  margin-top: 91.5pt;
  height: 216pt;
  object-fit: scale-down;
}

.phone-icon {
  width: 48pt;
  height: 48pt;
  position: absolute;
  right: 35.5pt;
  margin-top: -26px;
}

.phone-text {
  width: 90%;
  background: #fff;
  float: left;
  margin: 5%;
  margin-bottom: 10pt;
  border-radius: 40px;
}

.phone-news {
  margin: 10pt 15pt;
  border-top: 1px solid #DDDDDD;
}

.phone-title {
  font-family: Fredoka Light;
  font-weight: 600;
  font-size: 15pt;
  color: #2F9C36;
  margin: 13pt 15pt;
}

.phone-content {
  width: 120pt;
  float: left;
}
// .phone-news {
//   width:284pt;
//   height: 57pt;
//   margin: 0 30px;
//   border-top: 1px solid #DDDDDD;
// }

.phone-button {
  width: 157pt;
  padding: 12px 0;
  background: #2F9C36;
  border-radius: 8px;
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 9pt;
  color: #FFFFFF;
  line-height: 9pt;
  text-align: center;
  margin: 15pt 30% 20pt 30%;
}
</style>
