<template>
  <div :class="isMobile ? 'phone-head' : 'head'">
   
    <div>
      <img :class="isMobile ? 'phone-logo' : 'logo'" src="../assets/logo.png">
      <div class="title">HOME</div>
    </div>
    <div v-if="isMobile" class="phone-box">
      <img :class="isMobile && 'phone-icon'" src="../assets/menu.png" @click="menuClick">
    </div>
    <div class="menu-click" v-if="visible">
      <div class="menuA">
        <div style="padding: 4% 0;">HOME</div>
      </div>
      <div class="menuB">
        <div class="menu-btn">
          <img style="padding: 20px 20px 0px 20px;cursor: pointer;" src="../assets/icon_32_TG.png">
          <img style="padding: 20px 20px 0px 20px;cursor: pointer;" src="../assets/icon_32_Tw.png">
        </div>
      </div>
    </div>
    <div v-if="!isMobile" class="box">
      <!-- <img class="icon1" src="../assets/icon_32_TG.png">
      <img class="icon2" src="../assets/icon_32_Tw.png"> -->
      <img class="icon1" src="../assets/icon_Twitter.png" />
      <img class="icon2" src="../assets/icon_tg.png" />
      <img class="icon1" src="../assets/icon_youtube.png" />
      <img class="icon2" src="../assets/icon_email.png" />
            
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props:['isMobile'],
  data () {
    return {
      current: 0,
      visible: false
    }
  },
  methods: {
    menuClick() {
      this.current =  this.current + 1
      if (this.current % 2 === 0) {
        this.visible = false
      }else {
        this.visible = true
      }
      console.log(this.current , 'mmmmmm')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .head {
    width: 100vw;
    height: 64px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }
  .logo {
      width: 273px;
      height: 48px;
      margin-left: 50px;
      margin-top: 7px;
      float: left;
    }
    .title {
      font-family: Fredoka Light;
      font-weight: 1000;
      font-size: 18px;
      color: #58B34D;
      float: left;
      margin-top: 18px;
      margin-left: 51px;
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon1 {
      margin-right: 50px;
    }
    .icon2 {
      margin-right: 49px;
    }
    .phone-head {
      height: 60pt;
      background: rgba(255,255,255,0.5);
      border-radius: 16px;
      border: 1px solid #FFFFFF;
      margin: 0 16pt;
      position: relative;
      top: 19.5pt;
      z-index: 999;
    }
    .phone-logo {
      width: 161.5pt;
      height: 28pt;
      margin-top: 16pt;
      margin-left: 16pt;
    }
    .phone-box {
      position: absolute;
      right: 20px;
      top: 28%;
    }
    .phone-icon {
      width: 24pt;
      height: 24pt;
      cursor: pointer;
    }
    .menu-click {
      height: 120pt;
      background: #FFFFFF;
      border-radius: 40px;
      margin-top: 20pt;
      text-align: center;
    }
    .menuA {
      height: 50%;
      margin-top: 10%;
      margin: 0 15pt;
      border-bottom: 1px solid #DDDDDD;
      color: #2F9C36;
      font-size: 15pt;
      font-family: FredokaLight-SemiBold;
      font-weight: 600;
    }
    .menuB {
      height: 50%;
      display: flex;
      justify-content: center;
    }
    .menu-btn {
      width: 200pt;
    }
</style>
