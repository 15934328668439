<template>
  <div :class="!isMobile ? 'modularA' : 'phone-modularA'">
    <!-- <img :class="isMobile ? 'phone-img' : 'img'" src="../assets/A1.png" /> -->
    <img :class="isMobile ? 'phone-img' : 'img'" src="../assets/A1_00000_iSpt.png" />
    <div :class="isMobile ? 'phone-text' : 'text'">
      <img :class="isMobile ? 'phone-icon' : 'icon'" src="../assets/A1_penguin.png">
      <div :class="isMobile ? 'phone-title' : 'title'">Giggle Penguin Island</div>
      <div :class="isMobile ? 'phone-news' : 'news'">
        <span :class="isMobile ? 'phone-topic' : 'topic'">
          Welcome to Giggle Penguin Kingdom!
        </span>
        <div :class="isMobile ? 'phone-content' : 'content'">
          Once upon a time, in the sparkling blue ocean, there was a tiny, sunny spot called Giggle Island. This wasn't
          just any island—it was home to the happiest penguins you'd ever meet! It’s Giggle Penguin Kingdom!
        </div>
        <div :class="isMobile ? 'phone-content' : 'content'">
          These weren’t ordinary penguins. Each penguin had a bright, colorful beak and rubbery cute body which make
          them energetic all day long. They lived together in cozy snow huts and spent their days sliding down snowy
          hills, splashing in the water, and playing hide and seek in the ice caves.
        </div>
        <div :class="isMobile ? 'phone-content' : 'content'">
          The king was $PEPPY. $PEPPY was the smallest penguin with the biggest smile and a BNB crown. Every morning, he
          would waddle around the island with a giggle, making sure everyone was ready for a day full of fun.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModularA',
  props: ['isMobile'],
  data () {
    return {
      isAnimated: true
    };
  },
  methods: {

  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.modularA {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
  top: 0;
}

.phone-modularA {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
}

.img {
  width: 993px;
  height: 618px;
  margin: 231px 0 0 176px;
  float: left;
}

.text {
  width: 375px;
  height: 667px;
  background: #fff;
  float: left;
  margin: 207px 0 0 176px;
  border-radius: 40px;
}

.icon {
  position: absolute;
  margin: -35px 248px;
}

.title {
  font-family: Fredoka Light;
  font-weight: 600;
  font-size: 20px;
  color: #2F9C36;
  margin: 21px 30px;
}

.news {
  width: 315px;
  height: 592px;
  margin: 0 30px;
  border-top: 1px solid #DDDDDD;
}

.topic {
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 40px;
  margin-bottom: 5px;
}

.content {
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  word-break: keep-all;
  line-height: 24px;
  margin-bottom: 17px;
}

.phone-img {
  width: -webkit-fill-available;
  padding: 0 20.5pt;
  margin-top: 91.5pt;
}

.phone-icon {
  width: 48pt;
  height: 48pt;
  position: absolute;
  right: 35.5pt;
  margin-top: -26px;
}

.phone-text {
  width: 94%;
  // height: 242pt;
  background: #fff;
  float: left;
  margin: 3%;
  margin-bottom: 10pt;
  border-radius: 40px;
}

.phone-news {
  margin: 10pt 15pt;
  border-top: 1px solid #DDDDDD;
}

.phone-title {
  font-family: Fredoka Light;
  font-weight: 600;
  font-size: 15pt;
  color: #2F9C36;
  margin: 13pt 15pt;
}

.phone-content {
  font-family: FredokaLight-Regular;
  font-weight: 400;
  font-size: 9pt;
  color: #000000;
  word-break: keep-all;
  margin-bottom: 8pt;
}

.phone-topic {
  font-family: FredokaLight-Regular;
  font-weight: 450;
  font-size: 9pt;
  color: #000000;
  line-height: 30px;
}

</style>
