<template>
  <div :class="isMobile && 'phone-bg'">
    <div>
      <Header :isMobile="isMobile"></Header>
      <div :class="isMobile ? 'bg-A' : 'bg'" :style="{ marginTop: isMobile ? '' : '64px' }">
        <div v-if="!isMobile" class="cloud-container">
          <img src="../assets/cloud1.png" alt="云彩" class="cloud-image">
        </div>
        <div v-if="!isMobile" class="cloud-containerA">
          <img src="../assets/cloud3.png" alt="云彩" class="cloud-imageA">
        </div>
        <div v-if="!isMobile" class="cloud-containerB">
          <img src="../assets/cloud3.png" alt="云彩" class="cloud-imageB">
        </div>
        <div v-if="!isMobile" class="cloud-containerC">
          <img src="../assets/cloud4.png" alt="云彩" class="cloud-imageC">
        </div>
        <div v-if="!isMobile" class="cloud-containerD">
          <img src="../assets/cloud2.png" alt="云彩" class="cloud-imageA">
        </div>
        <div v-if="!isMobile" class="cloud-containerE">
          <img src="../assets/cloud4.png" alt="云彩" class="cloud-imageC">
        </div>
        <!-- 移动端 -->
        <div v-if="isMobile" class="phone-cloud-container">
          <img src="../assets/cloud1.png" alt="云彩" class="cloud-image">
        </div>
        <div v-if="isMobile" class="phone-cloud-containerA">
          <img src="../assets/cloud3.png" alt="云彩" class="cloud-imageC">
        </div>
        <div v-if="isMobile" class="phone-cloud-containerB">
          <img src="../assets/cloud3.png" alt="云彩" class="cloud-imageB">
        </div>
        <div v-if="isMobile" class="phone-cloud-containerC">
          <img src="../assets/cloud4.png" alt="云彩" class="cloud-imageC">
        </div>
        <!-- <img alt="logo" :class="!isMobile && 'logo'"
          :src="require('../assets/bg.png')"> -->
        <!-- <img alt="logo" :class="isMobile ? 'phone-logo' : 'logo'"
          :src="currentIndex === 0 && (isMobile ? require('../assets/pic.png') : require('../assets/bg.png'))"> -->
        <div :class="!isMobile ? 'container': 'phone-container'">
          <img v-if="!isMobile" class="logo" src="../assets/bg.png" />
          <img v-if="isMobile && currentIndex === 0" class="phone-logo" src="../assets/pic.png" />
        </div>
        <modularA :isMobile="isMobile" v-if="currentIndex === 1" />
        <modularB :isMobile="isMobile" v-if="currentIndex === 2" />
        <modularC :isMobile="isMobile" v-if="currentIndex === 3" />
        <modularD :isMobile="isMobile" v-if="currentIndex === 4" />
        <modularE :isMobile="isMobile" v-if="currentIndex === 5" />
        <modularF :isMobile="isMobile" v-if="currentIndex === 6" />
        <!-- 底部 -->
        <div v-if="isMobile" class="phone-footer">
          <div class="phone-icon">
            <img class="img-icon" src="../assets/logo-footer.png" />
            <img class="phone-img" src="../assets/icon_Twitter.png" />
            <img class="phone-img" src="../assets/icon_tg.png" />
            <img class="phone-img" src="../assets/icon_youtube.png" />
            <img class="phone-img" src="../assets/icon_email.png" />
          </div>
          <div class="phone-title">
            <div class="copy">©2024 Giggle Penguin Copyright</div>
            <div style="width: 49pt; ">Terms of use</div>
            <div style="width: 40pt; ">Privacy policy</div>
            <div style="width: 32pt; ">Media kit</div>
          </div>
        </div>
        <!-- <div v-if="!isMobile" class="footer">
          <div class="icon">
            <img class="img" src="../assets/logo-footer.png" />
            <div style="width: 200px; float: left; margin-top: 50px;">Terms of use</div>
            <div style="width: 200px;float: left; margin-top: 50px;">Privacy policy</div>
            <div style="width: 200px;float: left; margin-top: 50px;">Media kit</div>
            <img class="pc-img" src="../assets/icon_email.png" />
            <img class="pc-img" src="../assets/icon_youtube.png" />
            <img class="pc-img" src="../assets/icon_tg.png" />
            <img class="pc-img" src="../assets/icon_Twitter.png" />
          </div>
          <div class="pc-copy">©2024 Giggle Penguin Copyright</div>
        </div> -->
      </div>


      <div id="progressBar"
        class="fixed z-[99999] bottom-1/2 translate-y-1/2 transition-opacity duration-300 hidden md:block right-10 opacity-100"
        style="translate: none; rotate: none; scale: none; transform: translate(0px, 50%); bottom: 50%;">

        <div id="progressBarItems"
          class="rounded-full r-progress-bar-bg flex items-center justify-center   w-10 py-6   " style="opacity: 100;">

          <div class="flex flex-col justify-between relative progress-bar-box   w-4 h-[251px]   ">

            <div class="w-4 h-4 flex items-center justify-center cursor-pointer" v-for="(item, index) in data"
              :key="item.title" @click="onBarDot(index)">
              <!-- <div class="w-1.5 h-1.5 rounded-full transition-colors duration-500 bg-opacity-100"
                :class="{ 'bg-white': currentIndex !== index, 'bg-img': currentIndex === index }">
              </div> -->

              <div class="w-1.5 h-1.5 rounded-full transition-colors duration-500 bg-opacity-100"
                :class="{ 'bg-white': currentIndex !== index }">
              </div>
              <img v-if="currentIndex === index" alt="" fetchpriority="high" loading="eager" width="20" height="20"
                decoding="async" data-nimg="1"
                class="absolute left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 max-w-none transition-all duration-500   w-5 h-5   "
                style="color: transparent; top: 45px;" src="../assets/A1_penguin.png?tr:f-avif&amp;t=1">
            </div>

          </div>
        </div>
      </div>
      <div class="backTop"></div>

    </div>

  </div>


</template>

<script>
import Header from './Header.vue'
import modularA from './modularA.vue'
import modularB from './modularB.vue'
import modularC from './modularC.vue'
import modularD from './modularD.vue'
import modularE from './modularE.vue'
import modularF from './modularF.vue'
import { debounce } from 'lodash';
// import Swiper from 'swiper'

export default {
  name: 'HelloWorld',
  components: {
    Header,
    modularA,
    modularB,
    modularC,
    modularD,
    modularE,
    modularF,
  },
  props: {

  },

  data () {
    this.onBarDot = debounce(this.onBarDot, 600, {
      leading: true
    })
    return {
      currentIndex: 0, // 当前选中
      lastScrollTime: 0,
      screenWidth: window.innerWidth,
      isMobile: false,
      // 数组对象(展示图)
      data: [
        {
          title: 'img-1',
          imgUrl: ''
        },
        {
          title: 'img-2',
          imgUrl: ''
        },
        {
          title: 'img-3',
          imgUrl: ''
        },
        {
          title: 'img-4',
          imgUrl: ''
        },
        {
          title: 'img-5',
          imgUrl: ''
        },
        {
          title: 'img-6',
          imgUrl: ''
        },
        {
          title: 'img-7',
          imgUrl: ''
        }
      ]
    }
  },
  computed: {},
  mounted () {
    // window.addEventListener('mousewheel', this.handleMousewheel, { passive: false });
    // window.addEventListener('DOMMouseScroll', this.handleMousewheel);
    window.addEventListener('resize', this.handleResize);
    this.isMobile = this.screenWidth < 960 ? true : false
  },
  beforeDestroy () {
    // 在组件销毁前移除事件监听
    // window.removeEventListener('mousewheel', this.handleMousewheel);
    // window.removeEventListener('DOMMouseScroll', this.handleMousewheel);
  },
  methods: {
    // onClick (index) {
    //   this.current = index
    //   console.log(index, '-------')
    // },
    handleResize () {
      this.screenWidth = window.innerWidth;
      this.isMobile = this.screenWidth < 960 ? true : false
      console.log(this.isMobile, 'kkkkkk')
    },
    onBarDot (index) {
      this.currentIndex = index;
      console.log(index, '-------')
    },
    // handleMousewheel (e) {
    //   e.preventDefault();
    //   const now = Date.now();
    //   // 设置滚动间隔（500毫秒）
    //   if (now - this.lastScrollTime < 500) {
    //     return; // 如果在500毫秒内滚动，则忽略此次事件
    //   }
    //   this.lastScrollTime = now;
    //   const delta = e.wheelDelta || -e.detail;
    //   if (delta > 0) {
    //     this.onBarDot((this.currentIndex - 1 + this.data.length) % this.data.length);
    //   } else {
    //     this.onBarDot((this.currentIndex + 1) % this.data.length);
    //   }
    // }
  }

}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg {
  inset: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  background: linear-gradient(0deg, #1251A6, #97D6DA);
  overflow-y: auto;
}

.bg-A {
  inset: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  background: linear-gradient(0deg, #1251A6, #97D6DA);
  overflow-y: auto;
}

.logo {
  translate: none;
  rotate: none;
  scale: none;
  /* transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); */
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 1;
}

.bg-img {
  background-image: url('@/assets/A1_penguin.png');
  background-size: 20px 20px;
}

#progressBar {
  translate: none;
  rotate: none;
  scale: none;
  transform: translate(0px, 50%);
  float: right;
  margin-right: 41px;
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }
}

.duration-300 {
  transition-duration: .3s;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s;
}

.opacity-100 {
  opacity: 1;
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
}

/* #progressBar {
  width: 48px;
  height: 392px;
  background: linear-gradient(0deg, hsla(0, 0%, 100%, .1), hsla(0, 0%, 100%, .09));
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  overflow: hidden;
  float: right;
  margin-right: 41px;
  opacity: 100;
  border-radius: 9999px;
} */
.r-progress-bar-bg {
  background: linear-gradient(0deg, hsla(0, 0%, 100%, .1), hsla(0, 0%, 100%, .09));
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.w-10 {
  width: 2.5rem;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.flex-col {
  flex-direction: column;
}

.w-4 {
  width: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.h-\[251px\] {
  height: 251px;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}

.w-1\.5 {
  width: .375rem;
}

.h-1\.5 {
  height: .375rem;
}

.duration-500 {
  transition-duration: .5s;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition-duration: .15s;
}

.bg-opacity-100,
.bg-white {
  --tw-bg-opacity: 1;
}

.bg-white {
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.rounded-full {
  border-radius: 9999px;
}

.phone-bg {
  width: 100vw;
  height: 84vh;
  background: linear-gradient(0deg, #1251A6, #97D6DA);
}

.phone-logo {
  width: -webkit-fill-available;
  /* margin-top: 81.5pt; */
  padding: 19.5pt;
}

.footer {
  width: 100%;
  height: 240px;
  background: #fff;
  /* position: absolute; */
  bottom: 0;
  z-index: 99;
  /* display: none; */
}

.phone-footer {
  width: 100%;
  height: 108.5pt;
  background: #fff;
  position: absolute;
  bottom: 0;
}

.img {
  float: left;
  margin: 40px 73px 32px 32px;
}

.img-icon {
  float: left;
  margin: 20pt 15pt 20pt 15pt;
  width: 135pt;
  /* 或者指定具体的宽度 */
  height: 22.5pt;
  /* 确保高度自动缩放以保持图片的比例 */
  object-fit: scale-down;
}

.phone-img {
  float: right;
  width: 24pt;
  /* 或者指定具体的宽度 */
  height: 24pt;
  /* 确保高度自动缩放以保持图片的比例 */
  margin: 21pt 21pt 0pt 0pt;
}

.pc-img {
  float: right;
  margin: 40px 42px 32px 0;
}

.phone-icon {
  display: flex;
  justify-content: space-between;
}

.icon {
  height: 150px;
  font-size: 16px;
}

.phone-title {
  display: flex;
  justify-content: space-between;
  padding: 0 15pt;
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 12pt;
  color: #000000;
}

.copy {
  width: 119pt;
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 12pt;
  color: #999999;
}

.pc-copy {
  width: 100%;
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  text-align: center;
}

.cloud-container {
  overflow: hidden;
  width: 835px;
  /* 根据实际情况调整 */
  height: 386px;
  position: absolute;
  z-index: 99;
  bottom: 32%;
  object-fit: contain;
  /* left: 12%; */
  left: 0%;
}
.phone-cloud-container {
  overflow: hidden;
  width: 60%;
  /* 根据实际情况调整 */
  height: 386px;
  position: absolute;
  z-index: 99;
  bottom: 32%;
  object-fit: contain;
  /* left: 12%; */
  left: 0%;
}

.cloud-containerA {
  overflow: hidden;
  width: 845px;
  /* 根据实际情况调整 */
  height: 486px;
  position: absolute;
  z-index: 99;
  top: 0;
  object-fit: contain;
  left: 28%;
}
.phone-cloud-containerA {
  overflow: hidden;
  width: 60%;
  /* 根据实际情况调整 */
  height: 443px;
  position: absolute;
  z-index: 99;
  top: 28%;
  object-fit: contain;
  left: 40%;
}
.cloud-containerD {
  overflow: hidden;
  width: 552px;
  /* 根据实际情况调整 */
  height: 443px;
  position: absolute;
  z-index: 99;
  top: 0;
  object-fit: contain;
  left: 10%;
}

.cloud-containerB {
  overflow: hidden;
  width: 845px;
  /* 根据实际情况调整 */
  height: 486px;
  position: absolute;
  z-index: 90;
  bottom: 5%;
  left: 26%;
}
.phone-cloud-containerB {
  overflow: hidden;
  width: 50%;
  /* 根据实际情况调整 */
  height: 486px;
  position: absolute;
  z-index: 90;
  bottom: 11%;
  left: 3%;
}

.cloud-containerC {
  overflow: hidden;
  width: 752px;
  /* 根据实际情况调整 */
  height: 446px;
  position: absolute;
  z-index: 80;
  bottom: 14%;
  right: 0;
}
.phone-cloud-containerC {
  overflow: hidden;
  width: 50%;
  /* 根据实际情况调整 */
  height: 446px;
  position: absolute;
  z-index: 80;
  bottom: 14%;
  right: 0;
}

.cloud-containerE {
  overflow: hidden;
  width: 752px;
  /* 根据实际情况调整 */
  height: 446px;
  position: absolute;
  z-index: 80;
  bottom: 49%;
  right: 0;
}


.cloud-image {
  animation: slideOut 6s forwards;
  width: 100%;
  /* 根据实际情况调整 */
}

.cloud-imageA {
  animation: slideOutA 6s forwards;
  width: 100%;
  /* 根据实际情况调整 */
}

.cloud-imageB {
  animation: slideOutB 6s forwards;
  width: 100%;
  /* 根据实际情况调整 */
}

.cloud-imageC {
  animation: slideOutC 6s forwards;
  width: 100%;
  /* 根据实际情况调整 */
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-150%);
  }

  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@keyframes slideOutA {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateY(-150%);
  }

  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@keyframes slideOutB {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateY(150%);
  }

  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@keyframes slideOutC {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(150%);
  }

  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* 或者其他指定高度 */
  overflow: hidden;
  /* 防止图片溢出容器 */
}
.phone-container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100vh; /* 使.container的高度为视口高度 */
}

.logo {
  animation: grow 6s;
  /* 应用动画，持续时间2秒，无限次数 */
  width: 100%%;
  /* 初始宽度为容器的4分之一 */
  height: 86vh;
  /* 高度保持自动，根据宽度自动调整 */
  object-fit: contain;
}
.phone-logo {
  animation: grow 6s;
  /* 应用动画，持续时间2秒，无限次数 */
  width: 100%%;
  /* 初始宽度为容器的4分之一 */
  height: 86vh;
  /* 高度保持自动，根据宽度自动调整 */
  object-fit: contain;
}
@keyframes grow {
  0% {
    width: 50%;
    /* 动画开始时的宽度 */
  }

  100% {
    width: 100%;
    /* 动画结束时的宽度 */
  }
}

/* @keyframes 动画名{0%{opacity:1}100%{opacity:0}} */
</style>
