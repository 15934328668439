<template>
  <div :class="!isMobile ? 'modularB' : 'phone-modularB'">
    <img :class="isMobile ? 'phone-img' : 'img'" src="../assets/A2-2.png" />
    <div :class="isMobile ? 'phone-text' : 'text'">
      <img :class="isMobile ? 'phone-icon': 'icon'" src="../assets/A2_penguin.png" />
      <div :class="isMobile ? 'phone-title':'title'">Tokenomics</div>
      <div :class="isMobile ? 'phone-news' : 'news'">
        <h3>Total Supply</h3>
        <div style="margin-bottom: 12px;">10,000,000,000,000</div>
        <div :class="isMobile ? 'phone-content' : 'content'" v-for="item in data" :key="item.title">
          <div :class="isMobile ? 'phone-legend': 'legend'" :style="{ 'background': item.style }">
          </div>
          <span :class="isMobile ? 'phone-number':'number'">0.3% Game share</span>
        </div>
        <div :class="isMobile ? 'phone-button' : 'button'">
          OPERATIONS AND FUNDING
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "modularB",
  props: ['isMobile'],
  data () {
    return {
      data: [
        {
          title: "img-1",
          style: "#50C557",
        },
        {
          title: "img-2",
          style: "#53B4FF",
        },
        {
          title: "img-3",
          style: "#BF73FF",
        },
        {
          title: "img-4",
          style: "#FFA031",
        },
        {
          title: "img-5",
          style: "#FF6B31",
        },
        {
          title: "img-6",
          style: "#FD4AC8",
        },
        {
          title: "img-7",
          style: "#07BD83",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.modularB {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
  top: 0;
}
.phone-modularB {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
}

.img {
  width: 752px;
  height: 660px;
  margin: 210px 0 0 290px;
  float: left;
}

.text {
  width: 375px;
  height: 611px;
  background: #fff;
  float: left;
  margin: 207px 0 0 176px;
  border-radius: 40px;
}

.icon {
  position: absolute;
  margin: -35px 248px;
}

.title {
  font-family: Fredoka Light;
  font-weight: 600;
  font-size: 20px;
  color: #2f9c36;
  margin: 21px 30px;
}

.news {
  width: 315px;
  height: 592px;
  margin: 0 30px;
  border-top: 1px solid #dddddd;
}

h3 {
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin: 24px 0 0 0;
}

.content {
  height: 24px;
  margin-top: 20px
}

.legend {
  width: 24px;
  height: 24px;
  float: left;
  margin-right: 8px;
}

.button {
  width: 315px;
  height: 48px;
  background: #2F9C36;
  border-radius: 8px;
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  line-height:48px;
  text-align: center;
  margin-top: 64px;
}

.number {
  font-family: Fredoka Light;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  line-height: 24px;
}

.phone-img {
  width: -webkit-fill-available;
  padding: 0 20.5pt;
  margin-top: 91.5pt;
}
.phone-icon {
  width: 48pt;
  height: 48pt;
  position: absolute;
  right: 35.5pt;
  margin-top: -26px;
}
.phone-text {
  width: 88%;
  background: #fff;
  float: left;
  margin: 6%;
  margin-bottom: 10pt;
  border-radius: 40px;
}
.phone-news {
  margin: 10pt 15pt;
  border-top: 1px solid #DDDDDD;
}
.phone-title {
  font-family: Fredoka Light;
  font-weight: 600;
  font-size: 15pt;
  color: #2F9C36;
  margin: 13pt 15pt;
}
.phone-content {
  width: 120pt;
  float: left;
}
.phone-legend {
  width: 12pt;
  height: 12pt;
  float: left;
  margin-right: 4pt;
}
.phone-number {
  font-family: FredokaLight-Regular;
  font-weight: 400;
  font-size: 9pt;
  color: #000000;
  line-height: 24px;
}
.phone-button {
  width: 157pt;
  padding: 12px 0;
  background: #2F9C36;
  border-radius: 8px;
  font-family: Fredoka Light;
  font-weight: 500;
  font-size: 9pt;
  color: #FFFFFF;
  line-height: 9pt;
  text-align: center;
  margin: 120px 0 20px 30%;
}
</style>
